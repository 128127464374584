import { z } from 'zod';
import { AddressApiSchema } from './address-from-api';
import { RegionApiSchema, ShippingMethodsSchema } from './cart-from-api';
import { DiscountApiSchema } from './discount-from-api';
import { LineItemApiSchema } from './line-item-from-api';

const BaseApiSchema = z.object({
  id: z.string().optional(),
  created_at: z.nullable(z.string()).optional(),
  updated_at: z.nullable(z.string()).optional(),
  deleted_at: z.nullable(z.string()).optional(),
});

export const PaymentApiSchema = z.object({
  id: z.string(),
  provider_id: z.string().optional(),
  amount: z.nullable(z.number()).optional(),
  amount_refunded: z.nullable(z.number()).optional(),
  captured_at: z.nullable(z.string()).optional(),
  created_at: z.string().optional(),
  data: z
    .object({
      payment_method: z.nullable(z.string().optional()),
      payment_method_data: z
        .nullable(
          z.object({
            delay: z.number().or(z.string()).optional(),
          }),
        )
        .optional(),
    })
    .optional(),
});

export const TrackingLinkApiSchema = z.object({
  url: z.string().nullable().optional(),
  tracking_number: z.string().optional(),
  fullfilment_id: z.string(),
  metadata: z.any().optional(),
});

export const FulFillmentApiSchema = BaseApiSchema.extend({
  order_id: z.nullable(z.string()).optional(),
  shipped_at: z.nullable(z.string()).optional(),
  canceled_at: z.nullable(z.string()).optional(),
  items: z
    .nullable(
      z.array(
        z.object({
          fulfillment_id: z.nullable(z.string()).optional(),
          item_id: z.nullable(z.string()).optional(),
          quantity: z.nullable(z.number()).optional(),
          item: z.nullable(LineItemApiSchema).optional(),
        }),
      ),
    )
    .optional(),
  provider_id: z.nullable(z.string()).optional(),
  tracking_numbers: z.nullable(z.array(z.string())).optional(),
  tracking_links: z.array(TrackingLinkApiSchema).optional(),
  metadata: z
    .nullable(
      z.object({
        invoice_url: z.string().optional(),
      }),
    )
    .optional(),
});

export const OrderApiSchema = BaseApiSchema.extend({
  display_id: z.nullable(z.number()).optional(),
  external_id: z.nullable(z.string()).optional(),
  seller_order_id: z.nullable(z.string()).optional(),
  total: z.nullable(z.number()).optional(),
  subtotal: z.nullable(z.number()).optional(),
  tax_total: z.nullable(z.number()).optional(),
  shipping_total: z.nullable(z.number()).optional(),
  currency_code: z.nullable(z.string()).optional(),
  delivery_date: z.nullable(z.string().or(z.date())).optional(),
  receipt_url: z.nullable(z.string().url()).optional(),
  discounts: z.nullable(z.array(DiscountApiSchema)).optional(),
  fulfillments: z.nullable(z.array(FulFillmentApiSchema)).optional(),
  status: z
    .nullable(
      z.union([
        z.literal('reserved'),
        z.literal('pending'),
        z.literal('completed'),
        z.literal('archived'),
        z.literal('canceled'),
        z.literal('rejected'),
        z.literal('requires_action'),
      ]),
    )
    .optional(),
  fulfillment_status: z
    .nullable(
      z.union([
        z.literal('not_fulfilled'),
        z.literal('partially_fulfilled'),
        z.literal('fulfilled'),
        z.literal('partially_shipped'),
        z.literal('shipped'),
        z.literal('partially_returned'),
        z.literal('returned'),
        z.literal('canceled'),
        z.literal('requires_action'),
      ]),
    )
    .optional(),
  payment_status: z
    .nullable(
      z.union([
        z.literal('not_paid'),
        z.literal('awaiting'),
        z.literal('captured'),
        z.literal('partially_refunded'),
        z.literal('refunded'),
        z.literal('canceled'),
        z.literal('requires_action'),
      ]),
    )
    .optional(),
  items: z.nullable(z.array(LineItemApiSchema)).optional(),
  shipping_address: z.nullable(AddressApiSchema).optional(),
  billing_address: z.nullable(AddressApiSchema).optional(),
  shipping_methods: z.nullable(z.array(ShippingMethodsSchema)).optional(),
  payments: z.nullable(z.array(PaymentApiSchema).optional()).optional(),
  origin: z
    .nullable(
      z.union([
        z.literal('cart'),
        z.literal('import'),
        z.literal('document'),
        z.literal('quotation'),
      ]),
    )
    .optional(),
  region: z.nullable(RegionApiSchema).optional(),
  cart_id: z.nullable(z.string()).optional(),
  gift_card_total: z.nullable(z.number()).optional(),
  discount_total: z.nullable(z.number()).optional(),
  metadata: z
    .nullable(
      z.object({
        comment: z.string().optional(),
        invoice_url: z.string().optional(),
        delivery_note_url: z.string().optional(),
      }),
    )
    .optional(),
});

export type PaymentFromApi = z.infer<typeof PaymentApiSchema>;
export type FulfillmentFromApi = z.infer<typeof FulFillmentApiSchema>;
export type OrderFromApi = z.infer<typeof OrderApiSchema>;
