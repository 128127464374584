import { Language } from '../types/global';

export const IS_BROWSER = typeof window !== 'undefined';

export const LANGUAGES: Language[] = [
  {
    label: 'English',
    shortLabel: 'EN - US',
    countryCode: 'US',
    langCode: 'en-US',
  },
  {
    label: 'French',
    shortLabel: 'FR',
    countryCode: 'FR',
    langCode: 'fr-FR',
  },
  {
    label: 'German',
    shortLabel: 'DE',
    countryCode: 'DE',
    langCode: 'de-DE',
  },
  {
    label: 'Dutch',
    shortLabel: 'NL',
    countryCode: 'NL',
    langCode: 'nl-NL',
  },
  {
    label: 'Spanish',
    shortLabel: 'ES',
    countryCode: 'ES',
    langCode: 'es-ES',
  },
  {
    label: 'Italian',
    shortLabel: 'IT',
    countryCode: 'IT',
    langCode: 'it-IT',
  },
];
export const DB_LENGTH_UNIT = 'm';
export const DB_MASS_UNIT = 'kg';
export const DB_VOLUME_UNIT = 'L';
